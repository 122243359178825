<template>
  <div class="result">
    <v-container class="tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center tw-h-full tw-p-4 sm:tw-p-6" v-if="result">
      <div class="sm:tw-w-1/2" :class="[$vuetify.rtl ? 'sm:tw-pl-4' : 'sm:tw-pr-4']">
        <div class="result__title tw-text-2xl tw-font-semibold tw-text-center">
          {{ result[currentLocale].title }}
        </div>
        <div
          class="result__description tw-mt-6 tw-text-center"
          v-if="result[currentLocale].description"
          v-html="result[currentLocale].description"
        ></div>
        <v-img
          v-if="result[currentLocale].asset"
          :src="result[currentLocale].asset"
          class="tw-mt-6 sm:tw-hidden"
          max-width="100%"
        />
        <div class="result__actions tw-mt-6 tw-flex tw-items-center tw-justify-center">
          <v-btn :dark="isDark" large :href="result[currentLocale].cta.href" depressed color="primary" target="_blank">
            {{ result[currentLocale].cta.label }}
          </v-btn>
        </div>
      </div>
      <div class="tw-hidden sm:tw-w-1/2 sm:tw-block" :class="[$vuetify.rtl ? 'tw-pr-4' : 'tw-pl-4']">
        <v-img v-if="result[currentLocale].asset" :src="result[currentLocale].asset" max-width="100%" />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { sleep } from '@/utils/object.util'

export default {
  name: 'Result',
  props: {
    theme: {
      type: Object,
      required: true,
    },
  },
  async created() {
    if (this.result === null) {
      window.location.replace(`${window.location.origin}/${this.$route.params.code}`)
      await sleep(2000)
    } else {
      await this.incrementStat('gameEnded')
    }
  },
  computed: {
    ...mapState({
      result: state => state.global.result,
      currentLocale: state => state.global.currentLocale,
    }),
    isDark() {
      return this.theme.game.dark
    },
  },
  methods: {
    ...mapActions({
      incrementStat: 'global/incrementStat',
    }),
  },
}
</script>

<style lang="scss" scoped>
.result {
  background-color: var(--theme-game-background);
  min-height: 100%;
  color: var(--theme-game-color);
}
</style>
